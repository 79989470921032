(function () {
    'use strict';

    angular.module('languabooksApp')

        .config(['$stateProvider', 'LMConfig', function ($stateProvider, LMConfig) {

            $stateProvider
                .state('app.library.shelve', {
                    url: '/shelve',
                    controller: 'libraryShelveCtrl',
                    templateUrl: '/assets/ng1/library/shelve/shelve.html',
                    resolve: {
                        libraryCourses: function (token, coursesService, user) {


                            return coursesService.get(token, null, user.project).then(function (courses) {
                          
                                courses.forEach((x) => {
                                    x.$course_state = x.course_state ? JSON.parse(x.course_state) : {};                                     
                                });
                              
                                return courses;
                                 
                            });
                        },
                        cohorts: function (LMAPICache, token) {

                            return LMAPICache.cohorts(token, 'user');
                        },
                        latestCourses: function(LMAPI, token) {
                           
                           return LMAPI.latest(token).then(function (responce) {
                             
                                let courses = responce.data.map(function (item, i) {
                                    item.default = i + 1;
                                    item.$course_state = item.course_state ? JSON.parse(item.course_state) : {}; 
                                    // parse course scores
                                    if (item.scores)
                                        item.scores = JSON.parse(item.scores);
                
                                    return item;
                                });
                                return courses;
                            });
                        },
                        // quests in Library must show only for student role
                        // that if user have role teacher or admin
                        // api dont must call
                        currentTasks: function (taskService, credentials, commonService, user) {

                            let isStudent = user.roles.every(x => x == 'student');

                            if (isStudent) {
                                // get start of day UTC
                                // and format it
                                var _date = new Date();
                                let date = commonService.convertLocalDateToUTCUnix(_date);
        
                                // get tasks which are valid on current date                     
                                return taskService.get(date, null).then(function (responce) {
                                
                                    return responce.data.filter((x) => x.course_passed < x.courses)
                                                        .map(function (item) {
                                
                                                            item.date_from = commonService.getLocaleDate(item.startdate); 
                                                            item.date_to = commonService.getLocaleDate(item.enddate);
                                
                                                            return item;
                                                        })
                                });
                            } else {
                                return [];
                            }

                        },
                    }
                })
                .state('app.library.shelve.curriculums', {
                    url: '/curriculums',
                    controller: 'libraryShelveCurriculumsCtrl',
                    templateUrl: '/assets/ng1/library/shelve/students_collections.html',
                    resolve: {
                        disableCourses: function (LMAPI, libraryCourses, credentials, $q, user) {

                            let isStudent = user.roles.every(x => x == 'student');
                            if (isStudent) {

                                let promise = [];
                                let obj = {};
                                let disableCourses = [];
    
                                // get curriculum collection
                                let courses = libraryCourses.filter(x => x.enrol && x.enrol == 1)
                                                                .filter(x => x.curriculumtype == "assignments");
                                courses.forEach((y) => { y.$params = JSON.parse(y.params); });
    
                                let idsCourses = courses.map((x) => x.id);
                                
                                courses.forEach((course) => {
                                    promise.push({
                                        course: course.id,
                                        api: LMAPI.levels(credentials.session_token, course.curriculumtype, JSON.stringify(course.$params.p_levels))
                                    });
                                });
                                
                                promise.forEach((x) => {
                                    obj[x.course] = x.api;
                                });
    
                                return $q.all(obj).then((responce) => {
                                    
                                    idsCourses.forEach((x) => {
                                        let isDisableCourse = responce[x].every((x) => !x.assigned);
                                        if (isDisableCourse) {
                                            disableCourses.push(x);
                                        }
                                    })
                                    return disableCourses;
                                })
                            } else {
                                return [];
                            }
                        }
                    }
                })
                .state('app.library.shelve.books', {
                    url: '/books',
                    controller: 'libraryShelveBooksCtrl',
                    template: '<vms-course-list ng-model="courses" vms-token="token" vms-user="user" vms-page="page"></vms-course-list>'
                })
                .state('app.library.shelve.questionnaire', {
                    url: '/questionnaire',
                    controller: 'libraryShelveQuestionnaireCtrl',
                    template: '<vms-course-list ng-model="courses" vms-token="token" vms-user="user"></vms-course-list>'
                })
                .state('app.library.shelve.help', {
                    url: '/help',
                    controller: 'libraryShelveHelpCtrl',
                    template: '<vms-course-list ng-model="courses" vms-token="token" vms-user="user" vms-page="page"></vms-course-list>'
                })
                .state('app.library.notifications', {
                    url: '/notifications',                    
                    controller: 'districtNotificationsCtrl',
                    resolve: {
                        newNotifications: function(FRAPI, credentials){
                            FRAPI.clearNewNotifications();
                            return FRAPI.getNewNotifications().then((newNotifications) => {
                               
                               return newNotifications;
                            })
                        } 
                    },
                    templateUrl: '/assets/ng1/districtadmin/notifications/notifications.html'
                })
                .state('app.library.shelve.tests', {
                    url: '/tests',
                    controller: 'libraryShelveTestsCtrl',
                    template: '<vms-course-list ng-model="tests" vms-token="token" vms-user="user"></vms-course-list>'
                });
        }])

        .controller('libraryShelveCtrl', function ($scope, $rootScope, $state, credentials, libraryCourses, latestCourses, FRAPI, user, currentTasks, school) {
           
            $rootScope.shelveParameters = [];
            $scope.user = user;
            $scope.username = credentials.username;
            $scope.school = school;
            $scope.mode = localStorage.getItem('mode');

            // don't do code in current controller if user have active tasks
            // throw event main tab change
            $scope.$emit('libraryTabChange', 'library');
            $scope.$emit('adminTopTabChange', 'library');
            $scope.$emit('teacherModeChange', 'library');

            // watch for side tab changed
            $scope.$on('libraryActiveTab', function (event, data) {
                $scope.activeTab = data;
            });

            // get only enrolled courses
            let enrolled = libraryCourses.filter(x => x.enrol == 1);

            // divide courses by sub types
            let curriculums = enrolled.some(x => x.curriculumtype == "categories" || x.curriculumtype == "cohorts" || x.curriculumtype == "levels" ||
                x.curriculumtype == "grades" || x.curriculumtype == "assignments" || x.curriculumtype == "external" || x.curriculumtype == "lesson_plans");
            let books = enrolled.some(x => (x.curriculumtype == null || x.curriculumtype == 'test' || x.curriculumtype == 'guide') && x.executiontype == 'standalone_course' && x.mode == "remediation");
            let tests = enrolled.some(x => (x.curriculumtype == null || x.curriculumtype == 'test' || x.curriculumtype == 'guide') && x.executiontype == 'standalone_course' && x.mode == "assessment");
            let questionnaire = enrolled.some(x => x.curriculumtype == 'questionnaire');
            let help = enrolled.some(x => x.curriculumtype == 'help');
            let isStudent = user.roles.every(x => x == 'student');

            // set flags to hide some of the empty pages
            $scope.books = books;
            $scope.tests = tests;
            $scope.questionnaire = questionnaire;
            $scope.help = help;
            $scope.currentTasks = $rootScope.currentTasks ? $rootScope.currentTasks : currentTasks;
     
            $scope.isStudent = isStudent;
            // search latest opened courses
            // for the last 7 days
            $scope.latestCourses = latestCourses;
           
             $scope.$on('EventCurrentTasks', function (event, data) {
                $rootScope.currentTasks = data;
                $scope.currentTasks = data;

                $scope.isCurrentTasks = $scope.currentTasks.some((x) => x.course_passed < x.courses);
           
                if ($scope.isCurrentTasks){
                    $state.go('app.library.shelve.task');
                } else if (latestCourses && latestCourses.length > 15) {
                    $state.go('app.library.shelve.latest');
                }

                if (!$scope.$$phase) {
                    $scope.$apply();
                }
            });

            $scope.isCurrentTasks = $scope.currentTasks.some((x) => x.course_passed < x.courses);

            gotoSubPage();
                 
            function gotoSubPage() {


                if ($scope.isStudent) {
                    // this logic when we read book from quests/my books
                    // after exit book we shoud came back to current quest if quest is
                    // or another tab my books if books are mre then 15 
                    // or collections
                    if ($state.current.name == 'app.library.shelve.task' || 
                        $state.current.name == 'app.library.shelve.latest' || 
                        $state.current.name == 'app.library.shelve.curriculums') {
                        if ($scope.isCurrentTasks) {
                            $state.go('app.library.shelve.task');
                        } /*else if (unreadNotifiCount) {
                            $state.go('app.library.notifications');
                        }*/ else if (latestCourses && latestCourses.length > 0) {
                            $state.go('app.library.shelve.latest');
                        } else {
                            $state.go('app.library.shelve.curriculums');
                        }
                    }
                }
                
                // in case we arrived at root page
                // check what sub page to open first
                // based on courses available
                if ($state.current.name == 'app.library.shelve') {

                    // if (unreadNotifiCount) { $state.go('app.library.notifications'); return;}

                    if ($scope.isCurrentTasks && $scope.isStudent) 
                        $state.go('.task');
                    else if (latestCourses && latestCourses.length > 0 && $scope.isStudent) 
                        $state.go('.latest');
                    else  if (curriculums)
                        $state.go('.curriculums');
                    else if (books)
                        $state.go('.books');
                    else if (tests)
                        $state.go('.tests');
                    else if (questionnaire)
                        $state.go('.questionnaire');
                    else if (help)
                        $state.go('.help');
                    else
                        $state.go('.curriculums');
                }
                //open default first tab
                if ($state.current.name == 'app.teacher.library') {
                    $state.go('.curriculums');
                }
                if ($state.current.name == 'app.admin.school.library'){
                    $state.go('.curriculums');
                }

            }

            function onOpenQuest(quest) {

               // let mode = window.localStorage.getItem('mode');

                if ($scope.mode === 'teacher') {

                    $state.go('app.teacher.tasks.tupdate12', { task: quest, page: 'fromLibrary', state: 'current'});
                  
                } else if ($scope.mode === 'schooladmin') {

                    $state.go('app.admin.school.tasks.tupdate12', { task: quest, page: 'fromLibrary', state: 'current' });
                
                }
            }
            function onCreateQuest() {
                
                if ($scope.mode === 'teacher') {

                    $state.go('app.teacher.tasks.tadd', { state: 'current'});
                  
                } else if ($scope.mode === 'schooladmin') {

                    $state.go('app.admin.school.tasks.tadd', { state: 'current' });
                
                }
            }

            function onChangeQuest(quest) {
                $scope.$broadcast('change_quest', { quest: quest });
                if (!$scope.$$phase) {
                    $scope.$apply();
                }
            }
            $scope.$on('limits_books', function(event, data) {
             
                if ($scope.limitbooks === data.length && data.length >= 10) {
                    $scope.limitbooks = $scope.limitbooks + (Math.random() * 10 + 1);

                } else if ($scope.limitbooks === data.length && data.length < 10) {
                    $scope.limitbooks = $scope.limitbooks - (Math.random() * 10 + 1);
                } else {
                    $scope.limitbooks = data.length;
                }
            });
            
            $scope.onOpenQuest = onOpenQuest;
            $scope.onCreateQuest = onCreateQuest;
            $scope.onChangeQuest = onChangeQuest;  

        })

        .controller('libraryShelveCurriculumsCtrl', function ($scope, $state,  $filter, libraryCourses, user, openCourseService, token, disableCourses) {

            // throw event side tab change
            $scope.$emit('libraryTabChange', 'library');
            $scope.$emit('libraryActiveTab', 'curriculums'); 
            $scope.$emit('adminTopTabChange', 'library');
            $scope.$emit('teacherModeChange', 'library');

            window.localStorage.setItem('historyBackShelve', JSON.stringify([]));          
            //the first sort by name             
            $scope.collection = $filter('orderBy')(vmGetCollection(), 'name'); // need for table-cell showing  
      
            $scope.collection.forEach((x) => {
                x.$description = x.description;              
            })
            // try to localize collection descriptions
            // to user language
            if (user.lang) {

                $scope.collection.forEach(function (item) {

                    var temp = JSON.parse(item.params);

                    if (temp && temp.p_description) {

                        for (var key in temp.p_description) {
                            if (user.lang == key) {
                                item.p_description = temp.p_description[key];
                            }
                        }
                    }

                });
            };

            //sort by params p_sortorder
            $scope.collection = $filter('orderBy')($scope.collection, vmSortOrder); // need for table-cell showing     

            $scope.collectionGroup = vmGetChunks($scope.collection, 2); // need for table-cell showing   

            function vmGetCollection() {
                // is only one role student
                let isStudent = user.roles.every(x => x == 'student');
                // is role admin/teacher but not student
                let notStudent = user.roles.every(x => x != 'student');
                // is roles student and teacher/admin
                let isSomeStudent = user.roles.some(x => x == 'student');
                
                let collection;

                if (isStudent) {
                    // get curriculum collection
                    collection = libraryCourses.filter(x => x.enrol && x.enrol == 1)
                                            .filter(x => x.curriculumtype == "categories"
                                                || x.curriculumtype == "cohorts"
                                                || x.curriculumtype == "levels"
                                                || x.curriculumtype == "grades"
                                                || x.curriculumtype == "external"
                                                || x.curriculumtype == "lesson_plans"
                                                || x.curriculumtype == "assignments");

                    collection = collection.filter((x) => {
                        if (!disableCourses.some((y) => y === x.id)) {
                            return x;
                        }
                    });
                } else if (notStudent){
                    // get curriculum collection
                    collection = libraryCourses.filter(x => x.enrol && x.enrol == 1)
                                                    .filter(x => x.curriculumtype == "categories"
                                                        || x.curriculumtype == "cohorts"
                                                        || x.curriculumtype == "levels"
                                                        || x.curriculumtype == "external"
                                                        || x.curriculumtype == "lesson_plans");                    
                } else if (isSomeStudent) {
                    // get curriculum collection
                    collection = libraryCourses.filter(x => x.enrol && x.enrol == 1)
                                                .filter(x => x.curriculumtype == "categories"
                                                    || x.curriculumtype == "cohorts"
                                                    || x.curriculumtype == "grades"
                                                    || x.curriculumtype == "levels"
                                                    || x.curriculumtype == "external"
                                                    || x.curriculumtype == "lesson_plans");       
                }
                
                collection = collection.filter((x) => {
                    if (x.params) {
                        let params = JSON.parse(x.params);
                        if (params.p_reports_only) {
                            return !params.p_reports_only;
                        } else {
                            return x;
                        }
                    } 
                    return x;
                })

                return collection;
            }

            function vmSortOrder(course) {

                if (course.params) {

                    var params = JSON.parse(course.params);

                    if (params) {
                        return params.p_sortorder ? params.p_sortorder : "ZZZZ";
                    } else {
                        return "ZZZZ";
                    }
                } else {
                    return "ZZZZ";
                }
            }

            // open cohorts curriculum
            function vmOpenCohorts(course) {

                openCourseService.get(token, course);
            }

            $scope.openCourse = vmOpenCohorts;
        })

        .controller('libraryShelveBooksCtrl', function ($scope, $state, LMAPI, token, user) {
        
            $scope.$emit('libraryTabChange', 'library');
            $scope.$emit('libraryActiveTab', 'books'); 
            $scope.$emit('adminTopTabChange', 'library');
            $scope.$emit('teacherModeChange', 'library');
            $scope.page = 'books';

            $scope.$on('change_quest', function(event, data) {
               
                let books = localStorage.getItem('current_books');
                if ($scope.courses && $scope.courses.length > 0){
                    $scope.courses.forEach((item) => {
                        let _b = JSON.parse(books).find((y) => y === item.id);
                        item.isAssign = _b ? true : false;
                    })
                }
                if (!$scope.$$phase) {
                    $scope.$apply();
                } 
            });

            LMAPI.courses(token, user.project, null).then(function (courses) {

               let isStudent = user.roles.every(x => x == 'student');

               let books = !isStudent ? localStorage.getItem('current_books') : null;

                courses.forEach((x, i) => {
                    x.default = i + 1;     
                    x.$course_state = x.course_state ? JSON.parse(x.course_state) : {};  
                    x.isAssign = books ? vmShowAssignBooks(books, x.id) : false;                         
                });
                // get standalone courses
                $scope.courses = courses.filter(x => (x.curriculumtype == null || x.curriculumtype == 'test' || x.curriculumtype == 'guide') && x.mode == "remediation" && x.enrol && x.executiontype == 'standalone_course');

            }); 
            function vmShowAssignBooks(books, id) {
                let _b = JSON.parse(books).find((y) => y === id);
                return _b ? true : false;
            }
     
        })

        .controller('libraryShelveTestsCtrl', function ($scope, $state, libraryCourses, coursesService, token) {

            $scope.$emit('libraryTabChange', 'library');
            $scope.$emit('libraryActiveTab', 'tests'); 
            $scope.$emit('adminTopTabChange', 'library');
            $scope.$emit('teacherModeChange', 'library');

            // get test courses
            $scope.tests = libraryCourses.filter(x => (x.curriculumtype == null || x.curriculumtype == 'test' || x.curriculumtype == 'guide') && x.mode == "assessment" && x.executiontype == 'standalone_course' && x.enrol);

            // open course
            function vmOpenCourse(course) {

                // open course
                coursesService.runTest(token, course.id, null, $state.href($state.current.name, $state.current.params));
            }

            $scope.openCourse = vmOpenCourse;
        })

        .controller('libraryShelveHelpCtrl', function ($scope, $state, libraryCourses) {

            $scope.$emit('libraryTabChange', 'library');
            $scope.$emit('libraryActiveTab', 'help'); 
            $scope.$emit('adminTopTabChange', 'library');
            $scope.$emit('teacherModeChange', 'library');

            $scope.page = 'help';

            // get standalone courses
            $scope.courses = libraryCourses.filter(x => x.curriculumtype == 'help' && x.enrol);

        })

        .controller('libraryShelveQuestionnaireCtrl', function ($scope, $state, libraryCourses) {

            $scope.$emit('libraryTabChange', 'library');
            $scope.$emit('libraryActiveTab', 'questionnaire'); 
            $scope.$emit('adminTopTabChange', 'library');
            $scope.$emit('teacherModeChange', 'library');

            // get standalone courses
            $scope.courses = libraryCourses.filter(x => x.curriculumtype == 'questionnaire' && x.enrol);

        })

        .service('openCourseService', function ($state, coursesService) {

            this.get = function (token, course, task) {
                var taskId = task ? task.task_id : null;
                var _backUrl = $state.href($state.current.name, $state.current.params);
                let backUrl = _backUrl.split('?')[0];

                var curriculumParams = { id: course.id, back: backUrl };
              
                if (taskId) {
                    curriculumParams.taskId = taskId;
                }
                let mode = window.localStorage.getItem('mode');

                switch (course.curriculumtype) {
                    case 'lesson_plans':

                        if (mode === 'library') {
                            $state.go('app.library.curriculum.plans', curriculumParams);
                            break;
                        } else if (mode === 'teacher') {

                            $state.go('app.teacher.curriculum.plans', curriculumParams);
                            break;
                        } else if (mode === 'schooladmin') {
                            $state.go('app.admin.school.curriculum.plans', curriculumParams);
                            break;
                        }

                        break;

                    case 'categories':
                        if (mode === 'library') {
                             // open categories curriculum
                            $state.go('app.library.curriculum.categories', curriculumParams);
                            break;
                        } else if (mode === 'teacher') {
                            $state.go('app.teacher.curriculum.categories', curriculumParams);
                            break;
                        } else if (mode === 'schooladmin') {
                            $state.go('app.admin.school.curriculum.categories', curriculumParams);
                            break;
                        }
                     
                        break;

                    case 'cohorts':
                        
                        if (mode === 'library') {
                          // open cohort curriculum
                            $state.go('app.library.curriculum.cohorts', curriculumParams);
                            break;
                        } else if (mode === 'teacher') {
                            $state.go('app.teacher.curriculum.cohorts', curriculumParams);
                            break;
                        } else if (mode === 'schooladmin') {
                            $state.go('app.admin.school.curriculum.cohorts', curriculumParams);
                            break;
                        }
                      
                        break;

                    case 'levels':
                    case 'grades':
                    case 'assignments':

                        if (mode === 'library') {
                            // open levels curriculum
                            $state.go('app.library.curriculum.levels', curriculumParams);
                            break;
                          } else if (mode === 'teacher') {
                            $state.go('app.teacher.curriculum.levels', curriculumParams);
                            break;
                          } else if (mode === 'schooladmin') {
                            $state.go('app.admin.school.curriculum.levels', curriculumParams);
                            break;
                            
                          }

                        break;

                    case 'external':

                        // parse course params
                        let params = course.params ? JSON.parse(course.params) : null;

                        // if params is present
                        // and externla link specified
                        // than navigate to it
                        // otherwise fire alert
                        if (params && params.p_external_link) {

                            window.location = params.p_external_link;

                        } else {

                            alert('No link specified for Curriculum type external');
                        };

                        break;

                    default:
                        // open course
                        // notDirect required to detect
                        // if navigation to course was
                        // made by direct URL or through navigation menu
                        coursesService.runTest(token, course.id, null, backUrl, true, taskId);
                }
            }

        })
})();